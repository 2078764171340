<template>

<!-- 校园筛查 筛查前设置 机构管理yemian-->
  <div class="container">
    <p class="title">
      是否同步机构?
    </p>
    <el-switch
    @change="editLock"
      v-model="lock"
      :active-value="1"
      :inactive-value='0'
      active-color="#34BBEC"
      inactive-color="#f5f5f5">
    </el-switch>
    <p class="desc">
      开启后机构可导入或删除学生信息
    </p>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import homeService from '@/globals/service/management/home.js'
export default {
  data () {
    return {
      lock: 0
    }
  },
  created () {
    this.lock = storage.get('isLock') || 0
  },
  methods: {
    editLock (value) {
      homeService.schoolLock({ isLock: value }).then(res => {
        storage.set('isLock', value)
        this.$message({
          message: '设置成功',
          type: 'success'
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  color: #333;
  padding: 20px;
  .title, .desc{
    font-size: 18px;
    line-height: 30px;
  }
}
</style>
